import React, { useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { onKeyPressNumber } from "../../../customUtil/global"
import { deleteTaxFee, updateTaxFee } from "../../../services/taxFee"
const UpdateFeeBig = ({
  record,
  getListColections,
  listColections,
  listDataSourceOfTable,
  setListDataSourceOfTable,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  // console.log("record", record)
  useEffect(() => {
    setNumberBefore(Number(record.tax_fee_default) || 0)
    setNumberAfter(Number(record.tax_fee_default) || 0)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 46, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            console.log(e)
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Popconfirm
          placement="bottom"
          title={
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              {`設定ボタンを押下した時、すべて製品の ${record.title} の手数料は ${numberAfter}%になります。本当に実行しますか？ `}
            </div>
          }
          disabled={numberAfter == numberBefore}
          onConfirm={async () => {
            setIsLoading(true)
            const dataSent = {
              fee: Number(numberAfter),
              type: "taxFee",
              collectionId: record.id,
            }
            const { response } = await updateTaxFee(dataSent)
            if (response.status == 200) {
              setIsLoading(false)
              setNumberBefore(numberAfter)
              const array = listDataSourceOfTable?.map((el) => {
                if (el.id == record.id) {
                  return {
                    ...el,
                    is_custom_tax_fee: true,
                    tax_fee_default: numberAfter,
                  }
                }
                return el
              })
              setListDataSourceOfTable([...array])
              openNotificationChangeSuccess(
                "正常に更新されました。",
                "",
                "#389e0d"
              )
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
            }
          }}
          okText="設定"
          cancelText="キャンセル"
        >
          <Button
            style={{ height: 25, fontSize: 10 }}
            danger
            disabled={numberAfter == numberBefore}
            loading={isLoading}
          >
            {"編集"}
          </Button>
        </Popconfirm>
        <Popconfirm
          placement="bottomLeft"
          title={
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              {`削除ボタンを押下した時、すべて製品の ${
                record.title
              } の手数料は  ${
                listColections.filter((el) => el.is_custom_tax_fee == false)[0]
                  ?.tax_fee_default || 10
              }%になります。本当に実行しますか？ `}
            </div>
          }
          onConfirm={async () => {
            setIsLoadingDelete(true)
            const { response } = await deleteTaxFee({
              type: "taxFee",
              collectionId: record.id,
            })
            if (response.status == 200) {
              setIsLoadingDelete(false)

              getListColections()
              openNotificationChangeSuccess(
                "正常に更新されました。",
                "",
                "#389e0d"
              )
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
            }
          }}
          okText="削除"
          cancelText="キャンセル"
          disabled={!record.is_custom_tax_fee}
        >
          <Button
            style={{ height: 25, fontSize: 10 }}
            loading={isLoadingDelete}
            disabled={!record.is_custom_tax_fee}
          >
            {"削除"}
          </Button>
        </Popconfirm>
      </Space>
    </div>
  )
}

export default UpdateFeeBig
