// eslint-disable-next-line react/display-name
import { Button, Modal, Row, Col, Checkbox, Divider } from "antd"
import React, { useEffect, useState } from "react"

const ModalSelectCollectionMin = ({
  record,
  listDataSourceOfTable,
  setListDataSourceOfTable,
}) => {
  const [visible, setVisible] = useState(false)
  const [collectionMinChecked, setCollectionMinChecked] = useState(null)
  const handleCancel = () => {
    setVisible(false)
  }

  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues)
    setCollectionMinChecked(checkedValues)
  }

  const handleAddToTable = () => {
    if (!collectionMinChecked) {
      setVisible(false)
      return
    }
    const indexOfRecordInListDataSourceOfTable = listDataSourceOfTable
      .map(function (e) {
        return e.idMedium
      })
      .indexOf(record.idMedium)
    console.log(
      "indexOfRecordInListDataSourceOfTable",
      indexOfRecordInListDataSourceOfTable
    )
    const lengthRowHaveSameIdMedium = listDataSourceOfTable.filter(
      (el) => el.idMedium == record.idMedium
    ).length
    // eslint-disable-next-line no-empty
    if (
      collectionMinChecked.length ==
      record.items?.find((ele) => ele.id == record.idMedium)
        ?.other_product_collection?.length
    ) {
      console.log("hihi", record)
      const arrayComplement = collectionMinChecked.map((el) => {
        return {
          ...record,
          titleMin: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection?.filter((ele) => ele.id == el)[0]?.title,
          idMin: el,
          is_custom_tax_fee_min: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection?.filter((ele) => ele.id == el)[0]
            ?.is_custom_tax_fee,
          tax_fee_default_min: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection.filter((ele) => ele.id == el)[0]
            ?.tax_fee_default,
        }
      })
      //   console.log("arrayComplement", arrayComplement)

      const listDataSourceOfTableCopy = [...listDataSourceOfTable]
      listDataSourceOfTableCopy.splice(
        indexOfRecordInListDataSourceOfTable,
        lengthRowHaveSameIdMedium,
        ...arrayComplement
      )
      //   console.log("listDataSourceOfTableCopy", listDataSourceOfTableCopy)
      setListDataSourceOfTable(listDataSourceOfTableCopy)
    }
    if (
      collectionMinChecked.length <
      record.items?.find((ele) => ele.id == record.idMedium)
        ?.other_product_collection?.length
    ) {
      const arrayComplement = collectionMinChecked.map((el) => {
        return {
          ...record,
          titleMin: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection?.filter((ele) => ele.id == el)[0]?.title,
          idMin: el,
          is_custom_tax_fee_min: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection?.filter((ele) => ele.id == el)[0]
            ?.is_custom_tax_fee,
          tax_fee_default_min: record.items
            ?.find((ele) => ele.id == record.idMedium)
            ?.other_product_collection.filter((ele) => ele.id == el)[0]
            ?.tax_fee_default,
        }
      })
      const arrayComplement2 = [...arrayComplement, record]

      const listDataSourceOfTableCopy = [...listDataSourceOfTable]
      listDataSourceOfTableCopy.splice(
        indexOfRecordInListDataSourceOfTable,
        lengthRowHaveSameIdMedium,
        ...arrayComplement2
      )
      console.log("listDataSourceOfTableCopy", listDataSourceOfTableCopy)
      setListDataSourceOfTable(listDataSourceOfTableCopy)
    }
    setVisible(false)
  }

  useEffect(() => {
    const arrayHaveSameId = listDataSourceOfTable.filter(
      (el) => el.id == record.id
    )
    const arrayMinCollectionSelected = arrayHaveSameId.filter((el) => el.idMin)
    const arrayChecked = []
    arrayMinCollectionSelected.map((el) => {
      arrayChecked.push(el.idMin)
    })
    if (arrayChecked.length > 0) {
      setCollectionMinChecked(arrayChecked)
    }
  }, [listDataSourceOfTable])

  return (
    <>
      <Button
        style={{
          height: 25,
          fontSize: 12,
          background: "#ECB427",
          display: "flex",
          alignItems: "center",
          color: "#fff",
        }}
        onClick={() => {
          setVisible(true)
        }}
      >
        {"＋ カテゴリ設定"}
      </Button>
      <Modal
        title={
          <div style={{ fontWeight: 700, color: "#E60020" }}>
            {`${record.titleMedium}の小カテゴリ`}
          </div>
        }
        onCancel={handleCancel}
        footer={false}
        // closable={false}

        width={"50%"}
        visible={visible}
        // bodyStyle={{ borderRadius: "40px" }}
      >
        <div style={{ padding: "20px 5% 10px 5%" }}>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            value={collectionMinChecked}
            onChange={onChange}
          >
            <Row>
              {record.items
                ?.find((ele) => ele.id == record.idMedium)
                ?.other_product_collection?.map((el) => {
                  return (
                    <Col span={8}>
                      <Checkbox value={el.id}>{el.title}</Checkbox>
                    </Col>
                  )
                })}
            </Row>
          </Checkbox.Group>
        </div>
        <Divider />
        <div style={{ textAlign: "center" }}>
          <Button type="primary" onClick={handleAddToTable}>
            {"このカテゴリを個別に設定する"}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ModalSelectCollectionMin
