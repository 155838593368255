import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "gatsby"
import { Content } from "antd/lib/layout/layout"
import { Breadcrumb, Button, Typography } from "antd"
import FeeTax from "./feeTax"
import { GetListTaxFee } from "../../services/taxFee"
const { Title } = Typography

function SuperAdTaxManageMent(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [listColections, setListColections] = useState([])

  const getListColections = useCallback(async () => {
    setIsLoading(true)
    const { response } = await GetListTaxFee()
    if (response.status == 200) {
      setListColections(response.data.collections)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getListColections()
  }, [getListColections])

  const propsFee = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      listColections,
      setListColections,
      getListColections,
    }),
    [
      isLoading,
      setIsLoading,
      listColections,
      setListColections,
      getListColections,
    ]
  )

  console.log(propsFee)
  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
            color: "#111111",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-ad-tax-management">消費税管理</Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="flex justify-between w-full">
            <Title
              level={2}
              className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
            >
              消費税管理
            </Title>
            {/* <Link to="/super-ad-tax-management/for-product">
              <Button type="primary">商品消費税を設定する</Button>
            </Link> */}
          </div>
          <div>
            <FeeTax {...propsFee} />
          </div>
        </div>
      </Content>
    </div>
  )
}

export default SuperAdTaxManageMent
