import React, { useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { onKeyPressNumber } from "../../../customUtil/global"
import { deleteTaxFee, updateTaxFee } from "../../../services/taxFee"
const UpdateFeeMin = ({
  record,
  setListDataSourceOfTable,
  listDataSourceOfTable,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)

  const handleUpdateCollectionMin = async () => {
    setIsLoading(true)
    const { response } = await updateTaxFee({
      fee: Number(numberAfter),
      type: "taxFee",
      collectionId: record.idMin,
    })
    if (response.status == 200) {
      setIsLoading(false)
      setNumberBefore(numberAfter)
      const indexOfRecord = listDataSourceOfTable
        .map(function (e) {
          return e.idMin
        })
        .indexOf(record.idMin)

      listDataSourceOfTable.splice(indexOfRecord, 1, {
        ...record,
        is_custom_tax_fee_min: true,
        tax_fee_default_min: numberAfter,
      })

      const listItemsMinChange = record.items?.filter(
        (ele) => ele.id == record.idMedium
      )?.[0]?.other_product_collection

      const listItemsMinChangeAfter = listItemsMinChange?.map((ele) => {
        if (ele.id == record.idMin) {
          return {
            ...ele,
            tax_fee_default: numberAfter,
            is_custom_tax_fee: true,
          }
        } else {
          return ele
        }
      })

      const updateRecordItems = record.items?.map((ele) => {
        if (ele.id == record.idMedium) {
          return {
            ...ele,
            other_product_collection: listItemsMinChangeAfter,
          }
        } else {
          return ele
        }
      })

      const listDataSourceOfTableClone = listDataSourceOfTable.map((ele) => {
        if (ele.idMedium == record.idMedium) {
          return { ...ele, items: updateRecordItems }
        } else {
          return ele
        }
      })

      setListDataSourceOfTable([...listDataSourceOfTableClone])
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
    }
  }
  useEffect(() => {
    setNumberBefore(record.tax_fee_default_min)
    setNumberAfter(record.tax_fee_default_min)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 46, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Button
          style={{ height: 25, fontSize: 10 }}
          danger
          disabled={numberAfter == numberBefore}
          loading={isLoading}
          onClick={handleUpdateCollectionMin}
        >
          {"編集"}
        </Button>
        {record?.is_custom_tax_fee_min ? (
          <Popconfirm
            placement="bottomLeft"
            title={
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {`削除ボタンを押下した時、すべて製品の ${
                  record.title
                } の手数料は  ${
                  record.tax_fee_default_medium || record.tax_fee_default
                }%になります。本当に実行しますか？ `}
              </div>
            }
            onConfirm={async () => {
              setIsLoadingDelete(true)
              const { response } = await deleteTaxFee({
                type: "taxFee",
                collectionId: record.idMin,
              })
              if (response.status == 200) {
                setIsLoadingDelete(false)
                const indexOfRecord = listDataSourceOfTable
                  .map(function (e) {
                    return e.idMedium
                  })
                  .indexOf(record.idMedium)

                const listDataSourceOfTableByRecordID = listDataSourceOfTable.filter(
                  (el) => el.id == record.id
                )
                const listDataSourceOfTableByRecordIdMedium = listDataSourceOfTableByRecordID.filter(
                  (el) => el.idMedium == record.idMedium
                )

                const listDataSourceOfTableByRecordIdMin = listDataSourceOfTableByRecordIdMedium.filter(
                  (el) => el.idMin
                )
                const listIdMinHaveInListClicked = [
                  ...new Set(
                    listDataSourceOfTableByRecordIdMin?.map((el) => el.idMin)
                  ),
                ]

                const listItemsMinChange = record.items?.filter(
                  (ele) => ele.id == record.idMedium
                )?.[0]?.other_product_collection

                if (
                  listIdMinHaveInListClicked.length ==
                  listItemsMinChange?.length
                ) {
                  const indexOfElementChangeInTableSourceByIdMedium = listDataSourceOfTableByRecordIdMedium
                    .map(function (e) {
                      return e.idMin
                    })
                    .indexOf(record.idMin)

                  listDataSourceOfTableByRecordIdMedium.splice(
                    indexOfElementChangeInTableSourceByIdMedium,
                    1
                  )
                  const listItemsMinChangeAfter = listItemsMinChange?.map(
                    (ele) => {
                      if (ele.id == record.idMin) {
                        return {
                          ...ele,
                          tax_fee_default: record.tax_fee_default_medium,
                          is_custom_tax_fee: false,
                        }
                      } else {
                        return ele
                      }
                    }
                  )

                  listDataSourceOfTableByRecordIdMedium.push({
                    id: record.id,
                    title: record.title,
                    is_custom_tax_fee: record.is_custom_tax_fee,
                    tax_fee_default: record.tax_fee_default,
                    idMedium: record.idMedium,
                    titleMedium: record.titleMedium,
                    is_custom_tax_fee_medium: record.is_custom_tax_fee_medium,
                    items: record.items,
                    tax_fee_default_medium: record.tax_fee_default_medium,
                  })
                  const updateRecordItems = record.items?.map((ele) => {
                    if (ele.id == record.idMedium) {
                      return {
                        ...ele,
                        other_product_collection: listItemsMinChangeAfter,
                      }
                    } else {
                      return ele
                    }
                  })

                  const listDataSourceOfTableByRecordIdMediumAfter = listDataSourceOfTableByRecordIdMedium.map(
                    (ele) => {
                      return { ...ele, items: updateRecordItems }
                    }
                  )
                  listDataSourceOfTable.splice(
                    indexOfRecord,
                    listItemsMinChange?.length,
                    ...listDataSourceOfTableByRecordIdMediumAfter
                  )
                }
                if (
                  listIdMinHaveInListClicked.length < listItemsMinChange?.length
                ) {
                  const indexOfElementChangeInTableSourceByIdMedium = listDataSourceOfTableByRecordIdMedium
                    .map(function (e) {
                      return e.idMin
                    })
                    .indexOf(record.idMin)
                  const listDataSourceOfTableByRecordIdMediumClone = [
                    ...listDataSourceOfTableByRecordIdMedium,
                  ]
                  listDataSourceOfTableByRecordIdMediumClone.splice(
                    indexOfElementChangeInTableSourceByIdMedium,
                    1
                  )
                  const listItemsMinChangeAfter = listItemsMinChange?.map(
                    (ele) => {
                      if (ele.id == record.idMin) {
                        return {
                          ...ele,
                          tax_fee_default: record.tax_fee_default_medium,
                          is_custom_tax_fee: false,
                        }
                      } else {
                        return ele
                      }
                    }
                  )
                  const updateRecordItems = record.items?.map((ele) => {
                    if (ele.id == record.idMedium) {
                      return {
                        ...ele,
                        other_product_collection: listItemsMinChangeAfter,
                      }
                    } else {
                      return ele
                    }
                  })
                  const listDataSourceOfTableByRecordIdMediumCloneAfter = listDataSourceOfTableByRecordIdMediumClone.map(
                    (ele) => {
                      return { ...ele, items: updateRecordItems }
                    }
                  )
                  listDataSourceOfTable.splice(
                    indexOfRecord,
                    listDataSourceOfTableByRecordIdMedium?.length,
                    ...listDataSourceOfTableByRecordIdMediumCloneAfter
                  )
                }

                setListDataSourceOfTable([...listDataSourceOfTable])
                openNotificationChangeSuccess(
                  "正常に更新されました。",
                  "",
                  "#389e0d"
                )
              } else {
                openNotificationFail(
                  "ただいま、サーバが混み合っています。",
                  "しばらくしてから再度操作してください。",
                  "#f81d22"
                )
              }
            }}
            okText="削除"
            cancelText="キャンセル"
          >
            <Button
              style={{ height: 25, fontSize: 10 }}
              loading={isLoadingDelete}
            >
              {"削除"}
            </Button>
          </Popconfirm>
        ) : (
          <Button
            style={{ height: 25, fontSize: 10 }}
            onClick={() => {
              const indexOfRecord = listDataSourceOfTable
                .map(function (e) {
                  return e.idMedium
                })
                .indexOf(record.idMedium)

              const listDataSourceOfTableByRecordID = listDataSourceOfTable.filter(
                (el) => el.id == record.id
              )
              const listDataSourceOfTableByRecordIdMedium = listDataSourceOfTableByRecordID.filter(
                (el) => el.idMedium == record.idMedium
              )

              const listDataSourceOfTableByRecordIdMin = listDataSourceOfTableByRecordIdMedium.filter(
                (el) => el.idMin
              )

              const listIdMinHaveInListClicked = [
                ...new Set(
                  listDataSourceOfTableByRecordIdMin?.map((el) => el.idMin)
                ),
              ]
              const listItemsMinChange = record.items?.filter(
                (ele) => ele.id == record.idMedium
              )?.[0]?.other_product_collection
              if (
                listIdMinHaveInListClicked.length == listItemsMinChange?.length
              ) {
                const indexOfElementChangeInTableSourceByIdMedium = listDataSourceOfTableByRecordIdMedium
                  .map(function (e) {
                    return e.idMin
                  })
                  .indexOf(record.idMin)

                listDataSourceOfTableByRecordIdMedium.splice(
                  indexOfElementChangeInTableSourceByIdMedium,
                  1
                )
                listDataSourceOfTableByRecordIdMedium.push({
                  id: record.id,
                  title: record.title,
                  is_custom_tax_fee: record.is_custom_tax_fee,
                  tax_fee_default: record.tax_fee_default,
                  idMedium: record.idMedium,
                  titleMedium: record.titleMedium,
                  is_custom_tax_fee_medium: record.is_custom_tax_fee_medium,
                  items: record.items,
                  tax_fee_default_medium: record.tax_fee_default_medium,
                })

                listDataSourceOfTable.splice(
                  indexOfRecord,
                  listItemsMinChange?.length,
                  ...listDataSourceOfTableByRecordIdMedium
                )
              }
              if (
                listIdMinHaveInListClicked.length < listItemsMinChange?.length
              ) {
                const indexOfElementChangeInTableSourceByIdMedium = listDataSourceOfTableByRecordIdMedium
                  .map(function (e) {
                    return e.idMin
                  })
                  .indexOf(record.idMin)
                const listDataSourceOfTableByRecordIdMediumClone = [
                  ...listDataSourceOfTableByRecordIdMedium,
                ]
                listDataSourceOfTableByRecordIdMediumClone.splice(
                  indexOfElementChangeInTableSourceByIdMedium,
                  1
                )
                listDataSourceOfTable.splice(
                  indexOfRecord,
                  listDataSourceOfTableByRecordIdMedium?.length,
                  ...listDataSourceOfTableByRecordIdMediumClone
                )
              }

              setListDataSourceOfTable([...listDataSourceOfTable])
            }}
          >
            {"削除"}
          </Button>
        )}
      </Space>
    </div>
  )
}

export default UpdateFeeMin
